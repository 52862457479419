function ResponsiveImage(props) {
  const { src, alt, className, width, height } = props

  let suffix = props.suffix || ''

  const srcPath = `${process.env.PUBLIC_URL}/assets/images/${src}`

  return (
    <picture className="rounded-inherit">
      <source
        srcSet={srcPath + '@3x' + suffix + '.webp'}
        media="(min-width: 1360px)"
      />
      <source
        srcSet={srcPath + '@2x' + suffix + '.webp'}
        media="(min-width: 640px)"
      />
      <img
        src={srcPath + '@1x' + suffix + '.webp'}
        width={width + 'px'}
        height={height + 'px'}
        className={`rounded-inherit ${className || ''}`}
        alt={alt}
      />
    </picture>
  )
}

export default ResponsiveImage
