import WithApplyNowLink from '../../components/with-apply-now-link'
import IconButtonBase from '../../components/icon-button-base'
import { letsConnectIcon } from '../../utilities/constants'
import { useTranslation } from 'react-i18next'
import { forwardRef } from 'react'

const LetsConnect = forwardRef((props, ref) => {
  const { t: translate } = useTranslation()
  const t = (str) => translate('lets-connect.' + str)

  return (
    <div
      ref={ref}
      id="lets-connect"
      className="hidden md:block fixed bottom-12 right-[var(--container-edge)]"
    >
      <div className="scale-on-hover hide-label-on-scroll flex flex-row items-center justify-start">
        <div className="inline-block overflow-hidden">
          <div className="label">
            <WithApplyNowLink className="pointer-events-auto">
              {t('label')}
            </WithApplyNowLink>
          </div>
        </div>
        <WithApplyNowLink className="pointer-events-auto">
          <IconButtonBase
            wrapperClasses="ml-2 border border-inactive fill-current w-12 h-12 bg-light-grey "
            iconUrl={letsConnectIcon}
          />
        </WithApplyNowLink>
      </div>
    </div>
  )
})

export default LetsConnect
