import React, { useLayoutEffect, memo } from 'react'
import Divider from '../../components/divider'
import Community from './components/community/community'
import DarElPrimerPaso from './components/dar-el-primer-paso/dar-el-primer-paso'
import ForwardThingMarketing from './components/forward-thinking-marketing/forward-thinking-marketing'
import Hero from './components/hero/hero'
import LdmInternsAcademy from './components/ldm-interns-academy/ldm-interns-academy'
import LdmOrganization from './components/ldm-organization/ldm-organization'
import PartnersNetworking from './components/partners-networking/partners-networking'
import SkillsDeUnLdmer from './components/skills-ldmer/skills-de-un-ldmer'
import Universidades from './components/universidades/universidades'

const Home = memo(() => {
  useLayoutEffect(() => {}, [])

  return (
    <>
      <Hero />
      <ForwardThingMarketing />
      <Divider />
      <LdmInternsAcademy />
      <SkillsDeUnLdmer />
      <Divider />
      <PartnersNetworking />
      <LdmOrganization />
      <DarElPrimerPaso />
      <Divider />
      <Universidades />
      <Divider />
      <Community />
    </>
  )
})

export default Home
