import useAppContext from "../../utilities/app-context"


  function WithLetsConnectUniversitiesLink(props) {
    const buttonClasses = props.buttonClasses || ''
  const { openUniversitiesForm } = useAppContext()
  return (
    <button onClick={openUniversitiesForm} className={buttonClasses}>
      {props.children}
    </button>
  )
}

export default WithLetsConnectUniversitiesLink
