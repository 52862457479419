import React from "react";
import GlassCircle from "../../../../components/glass-circle";
import Sphere from "../../../../components/sphere";
import HeroContent from "./components/hero-content";
import HeroSlider from "./components/hero-slider";
import LetsConnectHeroCTA from "./components/lets-connect-hero";


function Hero() {
  
  return (
    <div id="hero" className="bg-hero min-h-screen sm:min-h-hero bg-cover overflow-hidden relative">
      <div className="container absolute min-h-screen sm:min-h-hero h-auto pt-[125px] left-[50%] translate-x-[-50%]">
        {/* <GlassCircle wrapperClasses="absolute w-3/4 top-[calc(30%+125px)] left-0 translate-x-[-55%]  bg-[#2480b0] bg-opacity-10 blur-2xl"/> */}
      </div>
      {/* <div className="absolute inset-0 backdrop-blur-lg" /> */}
      <div className="container absolute sm:min-h-hero h-auto pt-[125px] left-[50%] translate-x-[-50%]">
        <Sphere wrapperClasses="absolute w-[7%] top-0 left-0 translate-y-[-20%] translate-x-[-20%] blur-sm" />
      </div>
      {/* <div className="absolute top-0 bottom-0 left-[-10px] right-[-10px] backdrop-blur-md" /> */}
      <div className="container relative md:min-h-hero h-auto pt-[125px]  mx-auto">
        <div className="inner-container relative h-full ">
          <Sphere wrapperClasses="hidden md:block absolute w-[25%] md:w-[8.5%] top-[10%] md:top-0 left-[75%] md:left-[28%] md:translate-y-[-90%]" />
          <GlassCircle wrapperClasses="hidden md:block absolute w-[280vw] md:w-full top-[50%] left-[75%] translate-y-[-50%] md:translate-x-[-50%] bg-[#f9fbff33] border-2 border-white md:backdrop-blur-xl" />
          <GlassCircle wrapperClasses="absolute w-3/4 top-[125px] left-[100%] translate-x-[-55%] translate-y-[-50%] bg-[#ee7100] bg-opacity-[0.08] blur-2xl" />
          <GlassCircle wrapperClasses="absolute w-2/3 top-[50%] left-[75%] translate-y-[-50%] translate-x-[-50%] bg-inside-gradient-radial from-white via-white to-transparent md:blur-xl" />
          <div className="flex flex-col items-stretch justify-center h-full">
            <div className="flex flex-col-reverse md:flex-row justify-between items-center relative pb-16 md:pb-0">
              <LetsConnectHeroCTA />
              <HeroSlider />
              <HeroContent />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
