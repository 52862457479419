import LetsConnectUniversitiesThankYou from './lets-connect-universities-thank-you'
import LetsConnectUniversitiesForm from './lets-connect-universities-form'
import { useState } from 'react'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

const formActionUrl = 'https://hooks.zapier.com/hooks/catch/638494/bauqsl0/'

function LetsConnectUniversitiesFormWrapper() {
  const { i18n } = useTranslation()
  const [isFormSent, setIsFormSent] = useState(false)
  const [isFormSubmitting, setIsFormSubmitting] = useState(false)

  function formSubmitStart() {
    setIsFormSubmitting(true)
  }

  function formSubmitSuccess() {
    setIsFormSubmitting(false)
    setIsFormSent(true)
  }

  function formSubmitFailed() {
    setIsFormSubmitting(false)
    setIsFormSent(false)
  }

  const onSubmit = (data) => {
    console.log(data)
    formSubmitStart()
    axios
      .get(
        formActionUrl
        , {
          params: {
            'language': i18n.language,
            'university': data.university,
            'country': data.country,
            'city': data.city,
            'contact': data.contact,
            'phone': data.phone,
            'email': data.email,
            'position': data.position,
            'interest': data.interest,
            'comment': data.comment
          }
        }
      )
      .then((response) => {
        formSubmitSuccess()
      })
      .catch((error) => {
        formSubmitFailed()
      })
  }

  return isFormSent ? (
    <LetsConnectUniversitiesThankYou />
  ) : (
    <LetsConnectUniversitiesForm
      onSubmit={onSubmit}
      isFormSubmitting={isFormSubmitting}
    />
  )
}

export default LetsConnectUniversitiesFormWrapper
