import { useTranslation } from 'react-i18next'
import SkillSlider from './components/skills-slider'
import { memo } from 'react'

const SkillsDeUnLdmer = memo(() => {
  const { t: translate } = useTranslation()
  const t = (str, options) => translate('home.skills-ldmer.' + str, options)

  const skills = t('skills', { returnObjects: true })

  return (
    <section id="skills" className="bg-grey-gradient">
      <div className="container mx-auto pt-32 pb-16 overflow-x-hidden sm:overflow-x-visible">
        <div className="wrapper flex flex-col items-center justify-start mb-16 text-center">
          <h2 className="mb-4 fade-in-place">{t('title')}</h2>
          <p className="p1 max-w-[36em] fade-in-bottom">{t('content')}</p>
        </div>
        <SkillSlider skills={skills} />
      </div>
    </section>
  )
})

export default SkillsDeUnLdmer
