import { inPublicAssets } from "./functions"

export const diagonalArrowIcon = inPublicAssets('buttons/text-link-arrow.svg')
export const letsConnectIcon = inPublicAssets('buttons/lets-connect-q75.webp')
export const closeIcon = inPublicAssets('buttons/close-icon.svg')
export const sliderArrow = inPublicAssets('buttons/slider-arrow.svg')
export const accordionArrow = inPublicAssets('buttons/accordion-arrow.svg')

export const rightFixedPosition = "sm:right-[calc((100vw-640px)/2+(100vw*0.07))] md:right-[calc((100vw-1024px)/2+var(--breakpoint-padding-md))] lg:right-[calc((100vw-1360px)/2+var(--breakpoint-padding-lg))]"

export const modalHideTransitionLength = 0.75
export const modalShowTransitionLength = 0.75
