import { useLayoutEffect, useRef, useMemo } from 'react'
import AccordionItem from './components/accodion-item'
/**
 * Styles for accordion animation are in styles.css file
 * @param {} props 
 * @returns 
 */
function Accordion(props) {
  const cards = useMemo(()=>{return props.cards || []},[props.cards])
  const wrapperClasses = props.wrapperClasses || ''

  const accordionRef = useRef(null)
  const activeAccordionItemRef = useRef(0)
  const accordionItemsRef = useRef([])

  useLayoutEffect(() => {
    accordionRef.current.style.setProperty('--item-count', cards.items.length);
   }, [cards])

  const isActiveItem = (index) => {
    return index === activeAccordionItemRef.current ? true : null
  }

  const resetSiblings = () => {
    accordionItemsRef.current.forEach((element) => {
      element.classList.remove('active')
    })
  }

  const toggleAccordion = (index) => {
    return () => {
      if (accordionItemsRef.current[index].classList.contains('active')) {
        activeAccordionItemRef.current = null
        accordionItemsRef.current[index].classList.remove('active')
      } else {
        activeAccordionItemRef.current = index
        resetSiblings()
        accordionItemsRef.current[index].classList.add('active')
      }
    }
  }

  return (
    <div ref={accordionRef} id="ldm-organization-accordion" className={`accordion ${wrapperClasses}`}>
      {cards.items.map((card, index) => (
        <AccordionItem
          ref={(el) => (accordionItemsRef.current[parseInt(index)] = el)}
          card={card}
          key={index}
          active={isActiveItem(index)}
          onClick={toggleAccordion(index)}
        />
      ))}
    </div>
  )
}
export default Accordion
