import LetsConnectThankYou from "./lets-connect-thank-you"
import LetsConnectForm from "./lets-connect-form"
import { useState } from "react"
import axios from "axios"
import {useTranslation} from "react-i18next"

const formActionUrl = 'https://hooks.zapier.com/hooks/catch/638494/bauqvbz'

function LetsConnectFormWrapper() {

  const {i18n} = useTranslation()
  const [isFormSent, setIsFormSent] = useState(false)
  const [isFormSubmitting, setIsFormSubmitting] = useState(false)

  function formSubmitStart() {
    setIsFormSubmitting(true)
  }

  function formSubmitSuccess() {
    setIsFormSubmitting(false)
    setIsFormSent(true)
  }

  function formSubmitFailed() {
    setIsFormSubmitting(false)
    setIsFormSent(false)
  }
  
  const onSubmit = (data) => {
    console.log(data)
    formSubmitStart()
    var internsForm = new FormData()
    internsForm.append('language', i18n.language)
    internsForm.append('name', data.name)
    internsForm.append('profile', data.profile)
    internsForm.append('email', data.email)
    internsForm.append('country', data.country)
    internsForm.append('specialty', data.specialty)
    internsForm.append('cv', data.cv[0])
    internsForm.append('comment', data.comment)

    axios.post(
        formActionUrl, 
          internsForm
      )
      .then((response) => {
        formSubmitSuccess()
      }).catch(error => {
        formSubmitFailed()
      })
    
  }

  return isFormSent ? <LetsConnectThankYou /> : <LetsConnectForm onSubmit={onSubmit} isFormSubmitting={isFormSubmitting}/>

}

export default LetsConnectFormWrapper