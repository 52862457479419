import { closeIcon } from '../../utilities/constants'
import IconButtonBase from '../icon-button-base'

function CloseDrawerButton(props) {
  return (
    <button
      className={'rotate-on-hover absolute top-6 right-[var(--container-edge)]'}
      onClick={props.onClick}
    >
      <IconButtonBase
        iconUrl={closeIcon}
        wrapperClasses="bg-light-grey border-2 border-inactive fill-current w-12 h-12"
      />
    </button>
  )
}
export default CloseDrawerButton
