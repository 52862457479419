import ResponsiveImage from '../../../../../components/responsive-image'

const logoCommonClasses = ' relative w-full h-auto'
const commonWrapperClasses =
  ' logo-wrapper absolute translate-x-[-50%] translate-y-[-50%]'

function PartnersLogos() {
  return (
    <div
      className="w-full mt-8 md:-mt-16 md:ml-1/12 md:w-11/12"
    >
      <div className="inner-wrapper w-full pb-[77.9%] relative">
        {/* Fondo */}
        {/* gripo logos 1 */}
        <div className="absolute w-2/3 pb-[66%] left-0 bottom-[2%] speed-95">
          <ResponsiveImage
            src="skills-ldmer/skill-slider-item-fondo-2"
            alt="decoration"
            className="w-full h-auto object-contain absolute top-0"
            width="400"
            height="400"
            suffix="-q75"
          />
          <div
            className={'w-[15%] left-[73%] top-[45%] ' + commonWrapperClasses}
          >
            <ResponsiveImage
              src="partners-networking/waze-logo"
              alt="Waze logo"
              className={'' + logoCommonClasses}
              width="54"
              height="53"
              suffix="-q75"
            />
          </div>
          {/* <div
            className={'w-[24%] left-[24.1%] top-[51.3%]' + commonWrapperClasses}
          >
            <ResponsiveImage
              src="partners-networking/fb"
              alt="Facebook logo"
              className={'' + logoCommonClasses}
              width="86"
              height="42"
              suffix="-q75"
            />
          </div> */}
          <div
            className={'w-[25%] left-[50%] top-[27.24%] ' + commonWrapperClasses}
          >
            <ResponsiveImage
              src="partners-networking/google-partner"
              alt="Google partner badge"
              className={'' + logoCommonClasses}
              width="89"
              height="90"
              suffix="-q75"
            />
          </div>
          {/* <div
            className={
              'w-[16.8%] left-[50%] top-[61.3%] ' + commonWrapperClasses
            }
          > */}
          <div
            className={'w-[16.8%] left-[24.1%] top-[51.3%]' + commonWrapperClasses}
          >
            <ResponsiveImage
              src="partners-networking/spotify-logo"
              alt="Spotify logo"
              className={' ' + logoCommonClasses}
              width="60"
              height="60"
              suffix="-q75"
            />
          </div>
          <div
            className={
              'w-[36.7%] left-[50%] top-[80.33%] ' + commonWrapperClasses
            }
          >
            <ResponsiveImage
              src="partners-networking/socialbakers-logo-vector"
              alt="SocialBakers logo"
              className={' ' + logoCommonClasses}
              width="131"
              height="39"
              suffix="-q75"
            />
          </div>
        </div>
        {/* Grupo logos 2 */}
        <div className="logo-group-2-wrapper absolute top-0 left-[53.05%] w-[46.94%] pb-[46.94%] speed-925">
        <ResponsiveImage
            src="skills-ldmer/skill-slider-item-fondo-3"
            alt="decoration"
            className="w-full h-auto object-contain absolute top-0"
            width="400"
            height="400"
            suffix="-q75"
          />
          <div
            className={
              'w-[31.77%] top-[50.8%] left-[67.63%] ' + commonWrapperClasses
            }
          >
            <ResponsiveImage
              src="partners-networking/amazon-logo"
              alt="Amazon logo"
              className={' ' + logoCommonClasses}
              width="81"
              height="23"
              suffix="-q75"
            />
          </div>
          <div
            className={
              'w-[45.11%] top-[35.11%] left-[36.63%]' + commonWrapperClasses
            }
          >
            <ResponsiveImage
              src="partners-networking/mercado-libre-logo"
              alt="Mercado Libre logo"
              className={' ' + logoCommonClasses}
              width="115"
              height="59"
              suffix="-q75"
            />
          </div>
          <div
            className={
              'w-[25.5%] top-[64.14%] left-[51.15%]' + commonWrapperClasses
            }
          >
            <ResponsiveImage
              src="partners-networking/vtex-logo"
              alt="VTEX logo"
              className={' ' + logoCommonClasses}
              width="65"
              height="23"
              suffix="-q75"
            />
          </div>
        </div>
        {/* Grupo logos 3 */}
        <div className="logo-group-3-wrapper absolute top-[47.6%] left-[58.05%] w-[40.7%] pb-[40.7%] speed-90">
        <ResponsiveImage
            src="skills-ldmer/skill-slider-item-fondo-1"
            alt="decoration"
            className="w-full h-auto object-contain absolute top-0"
            width="400"
            height="400"
            suffix="-q75"
          />
          <div
            className={
              'w-[35%] left-[69.3%] top-[62.38%]' + commonWrapperClasses
            }
          >
            <ResponsiveImage
              src="partners-networking/blim-logo"
              alt="Blim logo"
              className={' ' + logoCommonClasses}
              width="78"
              height="22"
              suffix="-q75"
            />
          </div>
          <div
            className={
              'w-[32.5%] left-[51.7%] top-[26.4%]' + commonWrapperClasses
            }
          >
            <ResponsiveImage
              src="partners-networking/riot"
              alt="Riot logo"
              className={' ' + logoCommonClasses}
              width="72"
              height="23"
              suffix="-q75"
            />
          </div>
          <div
            className={
              'w-[46.1%] left-[41.3%] top-[45.88%]' + commonWrapperClasses
            }
          >
            <ResponsiveImage
              src="partners-networking/total-play-logo"
              alt="TotalPlay logo"
              className={' ' + logoCommonClasses}
              width="102"
              height="23"
              suffix="-q75"
            />
          </div>
          <div
            className={
              'w-[31.64%] left-[39.93%] top-[75%]' + commonWrapperClasses
            }
          >
            <ResponsiveImage
              src="partners-networking/pluto-logo"
              alt="Pluto Tv logo"
              className={' ' + logoCommonClasses}
              width="70"
              height="18.06"
              suffix="-q75"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PartnersLogos
