import { sliderArrow } from '../utilities/constants'
import IconButtonBase from './icon-button-base'

function ArrowIconButton({ onClick, buttonClasses, ...props }) {
  return (
    <button onClick={onClick} className={buttonClasses}>
      <IconButtonBase {...props} iconUrl={sliderArrow} />
    </button>
  )
}

export default ArrowIconButton
