import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import FieldSet from '../../../components/form-elements/fieldset'
import Input from '../../../components/form-elements/input'

function LetsConnectForm({
  onSubmit = () => console.log('No submit function provided'),
  isFormSubmitting = true,
}) {
  const { t: translate } = useTranslation()
  const t = (str, options) =>
    translate('lets-connect-universities.form.' + str, options)

  const formFields = t('fields', { returnObjects: true })



  /**
   * Form initialization
   */
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm()


  const inputComponents = {
    text: (props) => <input {...props} />,
  }



  return (
    <div className="flex flex-col md:flex-row items-start justify-start w-full">
      <div className="w-full md:w-1/2 pb-12">
        <h2 className="stagger">{t('title')}</h2>
      </div>
      <div className="w-full md:w-1/2 pb-12">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-2">
            {formFields.map((field, index) => {
              const {
                name,
                id,
                label,
                placeholder,
                type,
                options,
                classes,
                required, //retirar
                helperText,
                validation,
              } = field
              if (type === 'email') {
                validation.pattern.value =
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              }
              return (
                <div
                  key={index}
                  className={`flex flex-row col-span-1 stagger relative ${
                    ['textarea'].includes(type) ? 'md:col-span-2' : ''
                  }`}
                >
                  <FieldSet
                    errors={errors}
                    validation={validation}
                    name={name}
                    label={label}
                    helperText={helperText}
                    watch={watch}
                  >
                    <Input
                      type={type}
                      name={name}
                      id={id}
                      register={register}
                      validation={validation}
                      placeholder={placeholder}
                      options={options}
                      watch={watch}
                      errors={errors}
                    />
                  </FieldSet>
                </div>
              )
            })}
          </div>
          <div className="flex justify-start pt-12 pb-28">
            <button
              type="submit"
              value="submit"
              className="stagger w-[15em] flex justify-center items-center bg-main border-2 border-main transition-colors duration-300 hover:bg-transparent hover:text-main py-2 px-3 rounded-full text-white"
            >
              {isFormSubmitting ? t('submit.submitting') : t('submit.label')}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default LetsConnectForm
