import SkillSlide from './skill-slide'
import useSelector from '../../../../../hooks/use-selector'
import { useLayoutEffect, useRef } from 'react'
import gsap from 'gsap'

function SkillSlider(props) {
  const [q, ref] = useSelector()
  const skills = props.skills || []
  
  const backgroundsRef = useRef(skills.map(() => gsap.utils.random(1,3,1) ))

  useLayoutEffect(() => {

    

    //console.log(ref.current.offsetWidth)

    const initialScale = 0.33
    const duration = 20
    const stagger = (duration / 6.66)
    const skills = q('.skill-slide')
    
    const animationFunction = (element, index) => {
      const slideContent = element.children
      //console.log(slideContent)
      let animation = gsap.timeline()
      animation
        .fromTo(
          element,
          { xPercent: 0 },
          { xPercent: -100, duration: duration, ease: 'power1.inOut' }
      )
        .fromTo(
          slideContent,
          { opacity: 0, scale: initialScale },
          {
            keyframes: {
              autoAlpha: [0, 1, 0],
              scale: [initialScale, 1, initialScale],
            },
            ease: 'sine.inOut',
            duration: duration,
          },
          '<'
        )
        .to(element, { duration: stagger * (skills.length + 1) - duration })
        .repeat(-1)
        .progress(1 - (1 / skills.length) * index)

      /* console.log(
        'duracion',
        animation.duration(),
        'esfera',
        index,
        'inicio',
        1 - (1 / skills.length) * index
      ) */
      return animation
    }

    const animation = skills.map((element, index) => {
      return animationFunction(element, index)
    })

    return () => {
      animation.forEach(animation => {
        animation.kill()
      })
    }
  }, [])

  return (
    <div
      ref={ref}
      id="skill-slider"
      className="hide-off-viewport wrapper w-[160vw] md:w-[calc(((100vw-100%)/2)+100%)] relative left-[50%] translate-x-[-50%]"
    >
      {skills.map((skill, index) => (
        <SkillSlide
          key={index}
          slideWrapperClasses="inset-0 absolute skill-slide"
          slideClasses="pb-[25%] w-[25%]"
          background={backgroundsRef.current[index]}
        >
          {skill}
        </SkillSlide>
      ))}
      <div className='pb-[25%]'/>
    </div>
  )
}

export default SkillSlider
