import { useTranslation } from 'react-i18next'
// core version + navigation, pagination modules:
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Pagination, Navigation, EffectFade, Autoplay } from 'swiper'
// import Swiper and modules styles
import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'

import ArrowIconButton from '../../../../components/arrow-icon-button'

import { inPublicAssets } from '../../../../utilities/functions'
import { useState, useEffect, memo, useRef, useCallback } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import useWindowSize from '../../../../hooks/use-widow-size'

gsap.registerPlugin(ScrollTrigger)

const Community = memo(() => {
  const { t: translate } = useTranslation()
  const t = (str, options) => translate('home.community.' + str, options)

  const photoSwiperRef = useRef(null) //used to store thumbs slider instance
  const thumbsSwiperRef = useRef(null) //used to store thumbs slider instance

  const sliderWrapperRef = useRef(null) //used to check if slider in viewport

  const breakpointRef = useRef(1024)

  /**
   * Functions to control sliders
   * on desktop control comes from thumbs slider
   * on mobile devices photos slider controls the thumbs slider
   */

  const { width: windowWidth, height: windowHeight } = useWindowSize()

  const activeControllingSlider = useCallback(() => {
    if (windowWidth >= breakpointRef.current) {
      return thumbsSwiperRef.current
    }
    return photoSwiperRef.current
  })
  const controlledSlider = useCallback(() => {
    if (windowWidth >= breakpointRef.current) {
      return photoSwiperRef.current
    }
  })

  const nextSlide = useCallback(() => {
    photoSwiperRef.current.slideNext()
  })
  const previousSlide = useCallback(() => {
    photoSwiperRef.current.slidePrev()
  })

  const disableAutoplay = useCallback(() => {
    photoSwiperRef.current.autoplay.stop()
  })
  const enableAutoplay = useCallback(() => {
    photoSwiperRef.current.autoplay.start()
  })

  const photos = t('photoSlider', { returnObjects: true })

  useEffect(() => {
    photoSwiperRef.current.on('slideChange', () => {
      let currentSlide = photoSwiperRef.current.activeIndex - 3
      thumbsSwiperRef.current.slideToLoop(
        photoSwiperRef.current.activeIndex - 3
      )
    })
  }, [])

  /*   useLayoutEffect(() => { 
    ScrollTrigger.isInViewport(sliderWrapperRef.current, 0.05, () => { console.log("slider-in-viewport") })
  },[]) */

  return (
    <section id="community">
      <div className="container mx-auto pt-16 pb-32">
        <div className="wrapper">
          <div className="title-wrapper mb-16">
            <h2 className="w-11/12 ml-1/2">{t('title')}</h2>
          </div>
          <div
            ref={sliderWrapperRef}
            className="flex flex-col md:flex-row justify-between items-stretch"
            onMouseEnter={disableAutoplay}
            onMouseLeave={enableAutoplay}
          >
            <div className="photo-swiper w-full md:w-3/4">
              <div className="pb-[58%] h-0 w-full relative">
                <div className="absolute w-full h-full left-1/2 -translate-x-1/2">
                  <Swiper
                    onSwiper={(e) => {
                      photoSwiperRef.current = e
                    }}
                    slidesPerView={1}
                    spaceBetween={30}
                    loop={true}
                    effect={'fade'}
                    modules={[EffectFade, Autoplay]}
                    autoplay={{
                      delay: 3500,
                    }}
                    speed={1500}
                    className={'top-0 left-0 w-full h-full'}
                    loopAdditionalSlides={2}
                  >
                    {photos.map((photo, index) => {
                      let { src, suffix, ...imgAttr } = photo
                      return (
                        <SwiperSlide
                          key={index}
                          className="flex justify-center items-center"
                        >
                          <img
                            src={inPublicAssets(
                              'community/' + src + suffix + '.webp'
                            )}
                            {...imgAttr}
                            className="block h-full w-full object-cover rounded-xl"
                          />
                        </SwiperSlide>
                      )
                    })}
                  </Swiper>
                  <ArrowIconButton
                    onClick={previousSlide}
                    innerClasses="hover:scale-10 transition"
                    buttonClasses="md:hidden z-10 w-12 h-12 absolute top-1/2 left-0 md:-top-16 md:left-1/2 -translate-x-1/2 -translate-y-1/2 rotate-180"
                    wrapperClasses="w-full border border-main bg-disabled"
                  />
                  <ArrowIconButton
                    onClick={nextSlide}
                    buttonClasses="md:hidden z-10 w-12 h-12 absolute top-1/2 left-full md:-bottom-16 md:left-1/2 -translate-x-1/2 -translate-y-1/2"
                    wrapperClasses="w-full border border-main bg-disabled"
                  />
                </div>
              </div>
            </div>
            <div className="swiper-containe w-full md:w-1/4 rounded-xl py-2 md:py-0">
              <div className="h-full w-full relative">
                <div className="pb-[22%] h-0 md:pb-0 md:h-full w-full relative">
                  <div className="absolute w-full h-full left-1/2 -translate-x-1/2">
                    <div className="w-full h-full overflow-hidden rounded-xl">
                      <Swiper
                        onSwiper={(e) => {
                          thumbsSwiperRef.current = e
                        }}
                        slidesPerView={3}
                        spaceBetween={8}
                        loop={true}
                        watchSlidesProgress={true}
                        allowTouchMove={false}
                        speed={1500}
                        breakpoints={{
                          1024: {
                            direction: 'vertical',
                            spaceBetween: 16,
                          },
                        }}
                        onClick={function () {
                          /* console.log(
                        'thumbnail click',
                        this.clickedIndex,
                        '/',
                        photos.length,
                        ' parsed ',
                        gsap.utils.wrap(0, photos.length, this.clickedIndex + 1)
                      ) */
                          let clicked = gsap.utils.wrap(
                            0,
                            photos.length,
                            this.clickedIndex
                          )
                          photoSwiperRef.current.slideTo(clicked)
                        }}
                        className={'top-0 left-0 w-full h-full'}
                      >
                        {photos.map((photo, index) => {
                          let { src, suffix, ...imgAttr } = photo
                          return (
                            <SwiperSlide
                              key={index}
                              className="md:pl-4 flex justify-center items-center cursor-pointer"
                            >
                              <img
                                src={inPublicAssets(
                                  'community/' + src + suffix + '.webp'
                                )}
                                {...imgAttr}
                                className="block h-full w-full object-cover rounded-xl "
                              />
                            </SwiperSlide>
                          )
                        })}
                      </Swiper>
                    </div>
{/*                     <ArrowIconButton
                      onClick={previousSlide}
                      innerClasses="hover:scale-10 transition"
                      buttonClasses="hidden md:block z-10 w-12 h-12 absolute md:-top-16 md:left-1/2 -translate-x-1/2 -rotate-90"
                      wrapperClasses="w-full border border-main bg-disabled"
                    />
                    <ArrowIconButton
                      onClick={nextSlide}
                      buttonClasses="hidden md:block z-10 w-12 h-12 absolute md:-bottom-16 md:left-1/2 -translate-x-1/2 z-10 rotate-90"
                      wrapperClasses="w-full border border-main bg-disabled"
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
})

export default Community
