import { inPublicAssets } from '../../utilities/functions'
import prettyBytes from 'pretty-bytes'

function InputTypeFile({
  name = 'text', //field name,
  id = '', //field id,
  register = () => {}, //register function from react-hook-form,
  validation = {}, //validation object from react-hook-form,
  placeholder = '', //placeholder text,
  watch = () => {},
 }) {

  function isFileUploaded(file) {
    return file && file[0] !== undefined ? true : false
  }
  function getFileDetails(file) {
    if (isFileUploaded(file)) {
      return {
        name: file[0].name,
        size: prettyBytes(file[0].size),
        type: file.type,
      }
    }
    return false
  }

  function openFileDialog(id) {
    let fileInput = document.getElementById(id)
    fileInput.click()
  }


  return (
    <>
      <button
        className="h-full flex flex-row items-center justify-start w-full text-ellipsis "
        onClick={() => openFileDialog(id)}
        tabIndex={0}
      >
        <div className="w-6">
          <img
            src={inPublicAssets('/buttons/upload-icon.svg')}
            className={`w-full h-full object-contain ${isFileUploaded(watch(name)) ? 'hidden' : ''
              }`}
            alt="upload"
          />
          <img
            src={inPublicAssets(
              '/buttons/paperclip-check.svg'
            )}
            className={`w-3/4 h-3/4 object-contain ${!isFileUploaded(watch(name)) ? 'hidden' : ''
              }`}
            alt="upload"
          />
        </div>
        <div className="truncate">
          {getFileDetails(watch(name))?.name || placeholder}
        </div>
      </button>
      <input
        type="file"
        id={id}
        name={name}
        accept=".pdf"
        className="hidden"
        {...register(name, {
          ...validation,
        })}
        defaultValue={null}
      />
    </>
  )
}

export default InputTypeFile