import InputTypeFile from './input-type-file'
import Select from './select'

function Input({
  type = 'text',
  name = 'text', //field name,
  id = '', //field id,
  register = () => {}, //register function from react-hook-form,
  validation = {}, //validation object from react-hook-form,
  placeholder = '', //placeholder text,
  options = [], //options array,
  label = '',
  watch = () => { },
  errors = {},
}) {
  if (type === 'select') {
    return (
      <Select
        name={name}
        id={id}
        register={register}
        validation={validation}
        placeholder={placeholder}
        options={options}
        errors={errors}
      />
    )
  }
  if (type === 'file') {
    return (
      <InputTypeFile
        name={name}
        id={id}
        register={register}
        validation={validation}
        placeholder={placeholder}
        watch={watch}
      />
    )
  }
  if (type === 'textarea') {
    return(
    <textarea
      name={name}
      id={id}
      tabIndex={0}
      className="w-full h-20"
      {...register(name, {
        ...validation,
      })}
    />
  )}
  if (['email', 'text', 'tel'].includes(type)) {
    return (
      <input
        placeholder={placeholder || label || null}
        type={type}
        name={name}
        id={id}
        tabIndex={0}
        {...register(name, {
          ...validation,
        })}
      />
    )
  }
}

export default Input
