import IconButtonBase from '../../../components/icon-button-base'
import WithApplyNowLink from '../../../components/with-apply-now-link'
import { diagonalArrowIcon } from '../../../utilities/constants'

function ApplyNowLink(props) {
  return (
      <WithApplyNowLink>
        <div className="diagonal-arrow-on-hover flex flex-row items-center justify-start">
          <div className="inline-block">{props.label}</div>
          <IconButtonBase
          wrapperClasses="ml-2 border border-inactive fill-current w-12 h-12"
            iconUrl={diagonalArrowIcon}
          />
        </div>
      </WithApplyNowLink>
  )
}

export default ApplyNowLink
