import { gsap } from 'gsap'
import { useCallback, useLayoutEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import useSelector from '../../../hooks/use-selector'
import { fullViewportHeight, inPublicAssets } from '../../../utilities/functions'
import CloseDrawerButton from '../../../components/close-drawer-button/close-drawer-button'
import { modalHideTransitionLength, modalShowTransitionLength } from '../../../utilities/constants'
import useAppContext from '../../../utilities/app-context'
import LetsConnectUniversitiesFormWrapper from './lets-connect-universities-form-wrapper'

function LetsConnectUniversitiesDrawer({
  onSubmit = () => console.log('No submit function provided'),
  ...props
}) {
  const { t: translate } = useTranslation()
  const t = (str, options) => translate('lets-connect-universities.form.' + str, options)

  const background = props.background || inPublicAssets('backgrounds/menu-drawer-q75.webp')

  /**
   * Use selector to target inner items of the drawer
   */
  const [q, ref] = useSelector()

  /**
   * Ref used to prevent initial animation on load
   */
  const firstRender = useRef(true)

  const {isUniversitiesFormOpen, closeUniversitiesForm} = useAppContext()

  /**
   * Animation to open the drawer
   */
  const openDrawerAnimation = useCallback(() => {
    const timeline = gsap
      .timeline()
      .to(ref.current, {
        autoAlpha: 1,
        height: fullViewportHeight() + 'px',
        duration: modalShowTransitionLength,
      })
    .fromTo(
        q('.stagger'),
        { opacity: 0, yPercent: 20 },
        { opacity: 1, yPercent: 0, stagger: 0.1, duration: 0.5 },
        '-=0.1'
      )
    return timeline
  }, [])

  /**
   * Animation to close the drawer
   */
  const closeDrawerAnimation = useCallback(() => {
    const timeline = gsap.timeline({ immediateRender: false })
    timeline.to(
      ref.current,
      { height: 0, autoAlpha: 0, duration: modalHideTransitionLength }
    )
    return timeline
  }, [])

  useLayoutEffect(() => {
    /**
     * Use Effect to watch open/closed state of drawer
     */
    if (!firstRender.current) {
      if (isUniversitiesFormOpen) {
        openDrawerAnimation()
      }
      if (!isUniversitiesFormOpen) {
        closeDrawerAnimation()
      }
    } else {
      if (isUniversitiesFormOpen) {
        gsap.set(ref.current, { autoAlpha: 1, height: fullViewportHeight() })
      }
      if (!isUniversitiesFormOpen) {
        gsap.set(ref.current, { autoAlpha: 0, height: 0 })
      }
      firstRender.current = false
    }
  }, [isUniversitiesFormOpen])

  return (
    <div
      ref={ref}
      id="lets-connect-drawer"
      className="transition-none fixed top-0 left-0 w-screen h-0 bg-cover overflow-hidden invisible z-50"
    >
      <img src={background} loading="eager" className='absolute top-0 left-0 h-fullscreen w-full object-cover' />
      <div className="min-h-fullscreen max-h-full relative overflow-y-scroll">
        <div className="container mx-auto ">
          <div className="wrapper relative min-h-screen h-auto flex flex-col justify-start items-stretch">
            <div className="brand relative h-10 mt-6 mb-4 left-0">
              <img
                src={inPublicAssets('brand/logo LDM.svg')}
                className="h-full w-auto"
                alt={translate('navigation-bar.brand-link.alt')}
              />
            </div>
            <div className="flex flex-col items-start justify-center flex-grow">
              <LetsConnectUniversitiesFormWrapper />
            </div>
          </div>
        </div>
      </div>
      <CloseDrawerButton onClick={closeUniversitiesForm} />
    </div>
  )
}

export default LetsConnectUniversitiesDrawer
