import { useTranslation } from 'react-i18next'
import IconButtonBase from '../icon-button-base'

function SocialBar(props) {
  const wrapperClasses = props.wrapperClasses || ''
  const { t: translate } = useTranslation()
  const t = (str, options) => translate('social-networks.' + str, options)

  const socialNetworks = t('networks', { returnObjects: true })
 
  const iconPath = (src) => process.env.PUBLIC_URL + '/assets/images/social-networks/' + src

  return (
    <div className={`social-bar flex flex-col pb-8 ${wrapperClasses}`}>
      <p className="p1 mb-2">{t('title')}</p>
      <ul className="flex flex-row justify-start gap-2 items-center">
        {socialNetworks.map((network, index) => {
          let { alt, src, ...anchorProps } = network
          return (
            <li className="" key={index}>
              <a
                className="inline-block relative transition-colors duration-300 text-main hover:text-secondary"
                {...anchorProps}
              >
                <IconButtonBase iconUrl={iconPath(src)} alt={alt} wrapperClasses="border border-neutral w-12 h-12 hover:bg-lighten fill-current stroke-current"/>
              </a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default SocialBar
