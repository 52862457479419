import { useTranslation } from 'react-i18next';
import ResponsiveImage from '../../../../components/responsive-image';

function ForwardThingMarketing() {
  const { t: translate } = useTranslation();
  const t = (str) => translate("home.forward-thinking-marketing." + str);
  
  const awards = [
    "great-place-to-work-mexico",
    "great-place-to-work-panama",
    "employers-for-youth",
    "best-workplaces-for-women-mexico",
  ]

  const imagePath = process.env.PUBLIC_URL+"/assets/images/awards/"

  return (
    <section id="forward-thinking-marketing" className="overflow-hidden">
      <div className="container mx-auto">
        <div className='wrapper pt-28 pb-20'>
          <div className='inner-wrapper flex flex-col items-start justify-start w-full relative'>
            <div className='absolute h-80 md:h-full bottom-[calc(100%-8rem)] md:bottom-0 left-[70%]'>
              <ResponsiveImage src="backgrounds/evolucion-icon" alt="background" width="695" height="532" className="hidden md:block h-full w-auto object-contain max-w-none speed-925" suffix="-q75"/>
              <ResponsiveImage src="backgrounds/evolucion-icon-responsive" alt="background" width="328" height="251" className="md:hidden h-full w-auto object-contain max-w-none speed-925" suffix="-q75"/>
            </div>
            <div className="content md:ml-1/12 w-full md:w-5/12 mb-16 md:mb-28">
              <h2 className='mb-4 fade-in-place'>{t("title")}</h2>
              <p className='p1 fade-in-left'>{t("content")}</p>
            </div>
            <div className="image-wrapper w-full md:ml-1/24 md:w-7/12 flex flex-row justify-between md:justify-around relative">
              {awards.map((award, index) => (
                <div key={index} className="award-wrapper w-1/5">
                  <img src={imagePath + t("awards." + award + ".image") + "@3x.webp"} alt={t("awards." + award + ".alt")} title={t("awards." + award + ".title")} className={`desktop-lag-4`}/>
                </div>
              ))}
            </div>
            
          </div>
        </div> 
      </div>
    </section>
    )
}

export default ForwardThingMarketing;