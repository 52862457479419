function IconButtonBase(props) {
  const iconClasses = props.iconClasses || 'w-[33%] pb-[33%]'
  return (
    <div
      className={`circled-icon rounded-full overflow-hidden ${
        props.wrapperClasses || ''
      }`}
    >
      <div
        className={`relative w-full pb-[100%] block ${
          props.innerClasses || ''
        }`}
      >
        <div
          className={`iconWrapper absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] ${iconClasses}`}
        >
          {props.children || (
            <img
              src={props.iconUrl}
              alt={props.alt || 'decoration'}
              className="absolute w-full h-full object-contain"
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default IconButtonBase
