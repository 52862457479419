import DeviceDetector from 'device-detector-js'
import { gsap } from 'gsap'
import { ScrollSmoother } from 'gsap/ScrollSmoother'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useLayoutEffect, useRef } from 'react'
import useSelector from './hooks/use-selector'
import Footer from './layout/footer/footer'
import LetsConnectDrawer from './layout/lets-connect/components/lets-connect-drawer'
import LetsConnect from './layout/lets-connect/lets-connect'
import DrawerMenu from './layout/nav-bar/components/drawer-menu'
import NavigationBar from './layout/nav-bar/nav-bar'
import LetsConnectUniversitiesDrawer from './layout/universities-contact/components/lets-connect-universities-drawer'
import VideoModal from './layout/video-modal/video-modal'
import Home from './pages/home/home'
import useAppContext from './utilities/app-context'
import {
  fadeInBottom,
  fadeInLeft,
  fadeInPlace,
  fadeInPlaceChildren,
  fadeInRight,
  hideWhenOffViewPort,
  setScrolledClass,
} from './utilities/functions'
import modalBackground from './assets/backgrounds/menu-drawer-q75.webp'

const agent = navigator.userAgent
const deviceDetectorOptions = { skipBotDetection: true }
const deviceDetector = new DeviceDetector(deviceDetectorOptions)
const parsedAgent = deviceDetector.parse(agent)
const isMobile =
  ['mobile', 'tablet', 'smartphone'].includes(parsedAgent?.device?.type) ||
  ['iOS', 'Android'].includes(parsedAgent?.os?.name)
const isDesktop = !isMobile

let root = document.documentElement

if (isMobile) {
  console.log('is mobile device')
  root.style.setProperty('--screen-height', window.innerHeight + 'px') //If mobile we get a fixed height on load
  root.style.setProperty(
    '--screen-height-without-nav',
    window.screen.availHeight + 'px'
  ) //If mobile we get a fixed height on load
  root.style.setProperty('--is-mobile', 'initial')
  root.style.setProperty('--is-desktop', 'none')

  // default is "resize,visibilitychange,DOMContentLoaded,load" so we can remove "resize" from the list:
  //ScrollTrigger.config({ ignoreMobileResize: true })
}

gsap.registerPlugin(ScrollTrigger, ScrollSmoother)

function App() {
  const [q, ref] = useSelector()

  const scrollSmootherRef = useRef(null)

  const { navbarRef, isScrollPaused } = useAppContext()

  useLayoutEffect(() => {
    /**
     * Create CSS variables with background images URLs to use them from public folder
     * Need matching custom background image in tailwind config
     */
    root.style.setProperty(
      '--hero-bg-url',
      'url("' +
        process.env.PUBLIC_URL +
        '/assets/images/backgrounds/hero-background-q75.webp")'
    )

    root.style.setProperty(
      '--fullscreen-modal-url',
      'url("' +
        process.env.PUBLIC_URL +
        '/assets/images/backgrounds/menu-drawer-q75.webp")'
    )

    root.style.setProperty(
      '--footer-bg-url',
      'url(' +
        process.env.PUBLIC_URL +
        '/assets/images/backgrounds/footer-bg-q75.webp)'
    )

    scrollSmootherRef.current = ScrollSmoother.create({
      smooth: 1, // seconds it takes to "catch up" to native scroll position
      //effects: true, // look for data-speed and data-lag attributes on elements and animate accordingly
      normalizeScroll: ['iOS'].includes(parsedAgent?.os?.name) ? false : true, // prevents address bar from showing/hiding on most devices, solves various other browser inconsistencies
      ignoreMobileResize: true, // skips ScrollTrigger.refresh() on mobile resizes from address bar showing/hiding
      //smoothTouch: 0.1,

      onUpdate: (self) => {
        const delta = isDesktop ? 0 : 250
        if (self.getVelocity() > delta) {
          navbarRef.current.classList.add('hide')
        }
        if (self.getVelocity() < -delta) {
          navbarRef.current.classList.remove('hide')
        }
      },
    })
    scrollSmootherRef.current.effects('.speed-75', { speed: 0.75 })
    scrollSmootherRef.current.effects('.speed-80', { speed: 0.8 })
    scrollSmootherRef.current.effects('.speed-85', { speed: 0.85 })
    scrollSmootherRef.current.effects('.speed-90', { speed: 0.9 })
    scrollSmootherRef.current.effects('.speed-925', { speed: 0.925 })
    scrollSmootherRef.current.effects('.speed-95', { speed: 0.95 })
    if (isDesktop) {
      scrollSmootherRef.current.effects('.desktop-lag-2', { lag: 0.02 })
      scrollSmootherRef.current.effects('.desktop-lag-4', { lag: 0.04 })
      scrollSmootherRef.current.effects('.desktop-lag-6', { lag: 0.06 })
    }
    scrollSmootherRef.current.effects('.lag-2', { lag: 0.02 })
    scrollSmootherRef.current.effects('.lag-4', { lag: 0.04 })
    scrollSmootherRef.current.effects('.lag-6', { lag: 0.06 })
    scrollSmootherRef.current.effects('.lag-20', { lag: 0.2 })
  }, [])

  useLayoutEffect(() => {
    setScrolledClass(q, '#smooth-content', isDesktop)
    hideWhenOffViewPort(q, `.hide-off-viewport`, isDesktop)
    fadeInRight(q, `.fade-in-right`, isDesktop)
    fadeInLeft(q, `.fade-in-left`, isDesktop)
    fadeInBottom(q, `.fade-in-bottom`, isDesktop)
    fadeInPlace(q, `.fade-in-place`, isDesktop)
    fadeInPlaceChildren(q, `.fade-in-place-children`, isDesktop)
  }, [])

  useLayoutEffect(() => {
      scrollSmootherRef.current.paused(isScrollPaused)
   },[isScrollPaused])

  useLayoutEffect(() => {
    ScrollTrigger.refresh()
  }, [])

  return (
    <>
      <div
        ref={ref}
        id="smooth-wrapper"
        className="w-screen max-w-screen overflow-hidden"
      >
        <div id="smooth-content">
          <Home />
          <Footer />
        </div>
      </div>
      <NavigationBar ref={navbarRef} />
      <LetsConnect />
      <DrawerMenu background={modalBackground} />
      <LetsConnectDrawer background={modalBackground} />
      <VideoModal background={modalBackground} />
      <LetsConnectUniversitiesDrawer background={modalBackground} />
    </>
  )
}

export default App
