import sphereImage from "../assets/images/global/sphere@3x-q75.webp"

function Sphere(props) {
  return (
    <div className={`Sphere-wrapper rounded-full overflow-hidden ${props?.wrapperClasses || ""}`}>
      <div className="glass-circle-inner-wrapper pb-[100%] relative" >
        <img src={sphereImage} alt="sphere" className="absolute inset-0 h-full w-full object-contain" />
      </div>
    </div>
  );
}
export default Sphere;