import { forwardRef } from 'react'
import { inPublicAssets } from '../../../utilities/functions'

const AccordionItem = forwardRef((props,ref) => {
  const card = props.card

  const active = props.active || false

  return (
    <div ref={ref} className={`accordion-item ${active ? "active" : ""}`}>
      <div
        className="flex flex-row border-b border-borders items-center justify-between font-bold py-2 text-xl"
        onClick={props.onClick}
      >
        <div className="flex-grow-1">{card.title}</div>
        <div className="accordion-title-button-icon flex-shrink-0">
          <img
            src={
              inPublicAssets("buttons/accordion-arrow.svg")
            }
            alt=""
            className="w-[1.5em] h-[1.5em]"
          />
        </div>
      </div>
      <div className="accordion-collapse-panel">
        <p className="p1 pt-3">{card.content}</p>
      </div>
    </div>
  )
})

export default AccordionItem
