import WithApplyNowLink from '../../../../../components/with-apply-now-link'
import IconButtonBase from '../../../../../components/icon-button-base'
import { letsConnectIcon } from '../../../../../utilities/constants'
import { useTranslation } from 'react-i18next'
import { forwardRef } from 'react'

const LetsConnectHeroCTA = forwardRef((props, ref) => {
  const { t: translate } = useTranslation()
  const t = (str) => translate('lets-connect.' + str)

  return (
    <div
      ref={ref}
      id="lets-connect-hero-cta"
      className="md:hidden"
    >
      <WithApplyNowLink className="pointer-events-auto">
        <div className="scale-on-hover flex flex-row items-center justify-start">
          <div className="inline-block overflow-hidden">
            <div className="label">{t('label')}</div>
          </div>
          <IconButtonBase
            wrapperClasses="ml-2 border border-inactive fill-current w-12 h-12 bg-light-grey "
            iconUrl={letsConnectIcon}
          />
        </div>
      </WithApplyNowLink>
    </div>
  )
})

export default LetsConnectHeroCTA
