import {diagonalArrowIcon} from '../utilities/constants'

function TextLinkArrow(props) {
  return (
    <span>
      {props.children}
      <span className="link-arrow ml-2">
        <img
          src={diagonalArrowIcon}
          alt="arrow"
          className="w-auto h-2 object-contain inline-block"
        />
      </span>
    </span>
  )
}


export default TextLinkArrow;