import { gsap } from 'gsap'
import { useCallback, useLayoutEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import useSelector from '../../../hooks/use-selector'
import useAppContext from '../../../utilities/app-context'
import { fullViewportHeight, inPublicAssets } from '../../../utilities/functions'
import CloseDrawerButton from '../../../components/close-drawer-button/close-drawer-button'
import { modalHideTransitionLength, modalShowTransitionLength } from '../../../utilities/constants'

function DrawerMenu(props) {
  const { t: translate } = useTranslation()
  const t = (str, options) => translate('' + str, options)
  
  const background = props.background || inPublicAssets('backgrounds/menu-drawer-q75.webp')

  const [q, ref] = useSelector()

  const firstRender = useRef(true)

  const { isMenuOpen, closeMenu } = useAppContext()

  const closeDrawerAnimation = useCallback(() => {
    const timeline = gsap.timeline({immediateRender: false})
    timeline.to(ref.current, { height:0, autoAlpha:0, duration: modalHideTransitionLength })

    return timeline
  }, [])

  const openDrawerAnimation = useCallback(() => {
    const timeline = gsap.timeline()
    timeline
      .to(ref.current, { autoAlpha: 1, height: fullViewportHeight()+"px" , duration: modalShowTransitionLength })
      .fromTo(
        q('.menu-item'),
        { opacity: 0, yPercent: 20 },
        { opacity: 1, yPercent: 0, stagger: 0.1, duration: 0.5 },
        '-=0.1'
      )
    return timeline
  }, [])
  
  useLayoutEffect(() => {
    if (!firstRender.current) {
      if (isMenuOpen) {
        openDrawerAnimation()
      }
      if (!isMenuOpen) {
        closeDrawerAnimation()
      }
    } else {
      gsap.set(ref.current, { autoAlpha: 0, height: 0 })
      firstRender.current = false
    }
  }, [isMenuOpen])

  const drawerMenuItems = t('drawer-menu', { returnObjects: true })
  return (
    <div
      ref={ref}
      id="drawer-menu"
      className="transition-none fixed top-0 left-0 w-screen h-0 bg-cover overflow-hidden invisible z-50"
    >
      <img src={background} loading="eager" className='absolute top-0 left-0 h-fullscreen w-full object-cover' />
      <div className="min-h-fullscreen max-h-full relative overflow-y-scroll">
        <div className="container mx-auto ">
          <div className="wrapper relative min-h-screen h-full flex flex-col justify-start items-stretch">
            <div className="brand relative h-10 mt-6 mb-4 left-0">
              <img
                src={inPublicAssets('brand/logo LDM.svg')}
                className="h-full w-auto"
                alt={translate('navigation-bar.brand-link.alt')}
              />
            </div>
            <div className="flex flex-col items-start justify-center flex-grow">
              <ul className="flex w-full flex-col items-start md:flex-wrap justify-center md:justify-start py-16 md:py-0 text-[2rem] md:text-3xl h-full  md:h-[8em] leading-none">
                {drawerMenuItems.map((item, index) => {
                  let { text, ...attr } = item
                  let counter = ('00' + (index + 1)).slice(-2)
                  return (
                    <li
                      className="menu-item relative pl-6 mb-8 w-full md:w-1/2"
                      key={index}
                    >
                      <a {...attr}>{text}</a>
                      <div className="counter absolute top-0 left-0 text-base">
                        {counter}
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <CloseDrawerButton onClick={closeMenu} />
    </div>
  )
}

export default DrawerMenu
