function IconCard(props) {
  const icon = props.icon || null
  const title = props.title || null
  const className = props.className || ''

  const src = `${process.env.PUBLIC_URL}/assets/images/${icon}.svg`
  return (
    <div className={`p-4 pb-8 ${className || ''}`}>
      <div
        className={`flex flex-col items-center justify-center relative p-10 border border-dashed border-neutral rounded-md text-center `}
      >
        <div className="absolute top-0 left-[50%] translate-x-[-50%] translate-y-[-50%] h-16 w-16 rounded-full bg-white p-3 border border-dashed border-neutral flex justify-center items-center">
          <img src={src} alt={title} className="w-3/4 h-auto object-contain" />
        </div>
        <div className="flex flex-row h-12 justify-center items-center">
          <p className="">{title}</p>
        </div>
      </div>
    </div>
  )
}

export default IconCard
