import { useTranslation } from 'react-i18next'
import ActionWrapper from '../../../components/action-wrapper'

function LetsConnectThankYou() {
  const { t: translate, i18n } = useTranslation()
  const t = (str, options) => {
    let baseString = 'lets-connect.form.thank-you.'
    return translate(baseString + str, options)
  }


  return (
    <div className="-mt-10">
      <div className="h2 mb-8">{t('title')}</div>
      <p className="p1 mb-8 whitespace-pre-line">{t('message')}</p>
      {t('cta', { returnObjects: true }).map((cta, index) => {
        let { text, linkText, ...props } = cta
        return (
          <p className="p1 mb-8 ">
            {text && linkText && text}
            <ActionWrapper {...props} arrow className="transition-opacity durantion-350 font-bold hover:opacity-75 underline underline-offset-2">{linkText || text}</ActionWrapper>
          </p>
        )
      })}
    </div>
  )
}

export default LetsConnectThankYou
