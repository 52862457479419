function TopMenuLinks(props) {
  const links = props.links || []
  return (
    <ul className="hidden md:flex flex-row items-center justify-center flex-grow">
      {links.map((link, index) => {
        let { text, ...attr } = link
        return (
          <li key={index} className="px-4">
            <a {...attr}>{text}</a>
          </li>
        )
      })}
    </ul>
  )
}

export default TopMenuLinks