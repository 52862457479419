import { useTranslation } from 'react-i18next';
import IconCard from '../../../../components/icon-card';

function LdmInternsAcademy() {
  const { t: translate } = useTranslation();
  const t = (str,options) => translate("home.ldm-interns-academy." + str, options);

  const cards = t('cards', { returnObjects: true })

  const iconRoute = (icon) => `academy/${icon}`

  return (
    <section id="forward-thinking-marketing" className="">
    <div className="container mx-auto">
        <div className="wrapper pt-16 md:pt-20 pb-20">
          <div className="md:ml-[calc(100%/12)] md:w-11/12 flex flex-col md:flex-row justify-start md:justify-between items-start pb-[4rem]">
            <h2 className="w-full md:w-1/3 mb-5 fade-in-place">{t("title")}</h2>
            <p className="p1 w-full md:w-1/2 fade-in-right">{t("content")}</p>
          </div>
          <div className="flex flex-col md:flex-row justify-start md:justify-center items-stretch md:items-start flex-wrap -mx-4 fade-in-place-children">
            {cards.map((card, index) => (
              <IconCard icon={iconRoute(card.icon)} title={card.title} className="w-full md:w-1/3" key={index} />
            ))}
          </div>
        </div>
      </div>
      </section>
  )
}

export default LdmInternsAcademy;