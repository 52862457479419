import { useTranslation } from 'react-i18next'
import OrderedList from '../../../../components/ordered-list/ordered-list'
import ResponsiveImage from '../../../../components/responsive-image'

function DarElPrimerPaso() {
  const { t: translate } = useTranslation()
  const t = (str, options) => translate('home.dar-el-primer-paso.' + str, options)

  const orderedListItems = t('ordered-list', { returnObjects: true })

  return (
    <section id="dar-el-primer-paso" className="md:bg-grey-gradient relative">
      <div className="container mx-auto pt-20 pb-20">
        <div className="wrapper">
          <div className="title-wrapper md:text-center mb-10 md:mb-20">
            <h2 className="fade-in-place">{t('title')}</h2>
          </div>
          <div className="flex flex-col md:flex-row items-start md:items-center justify-start md:justify-between">
            <div className="w-full md:ml-1/12 md:w-1/3 mb-10 md:mb-0 fade-in-place">
              <ResponsiveImage
                src="dar-el-primer-paso/dar-el-primer-paso"
                alt={t('photo-alt')}
                className="w-full md:w-10/12 h-full object-center object-contain"
                width="341"
                height="336"
                suffix="-q75"
              />
            </div>
            <div className="w-full md:w-1/2 fade-in-right">
              <OrderedList
                heading={orderedListItems.heading || null}
                items={orderedListItems.items}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DarElPrimerPaso
