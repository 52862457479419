import { useTranslation } from 'react-i18next'
import Accordion from '../../../../components/accordion/accordion'
import ResponsiveImage from '../../../../components/responsive-image'

function LdmOrganization() {
  const { t: translate } = useTranslation()
  const t = (str, options) => translate('home.ldm-organization.' + str, options)

  const cards = t('cards', { returnObjects: true })

  return (
    <section id="ldm-organization">
      <div className="container mx-auto relative">
        <div className="absolute hidden md:block h-full right-[75%] top-[-2rem] zindex-0 ">
          <ResponsiveImage
            src="backgrounds/blue-glass-with-sphere"
            alt="Ldm organization"
            width="466.63"
            height="466.63"
            suffix="-q75"
            className="h-full w-auto max-w-none speed-80"
          />
        </div>
        <div className="wrapper relative">
          <div className="title-wrapper mb-16 md:text-center">
            <h2 className="mb-4 fade-in-place">{t('title')}</h2>
            <p className="p1 fade-in-bottom">{t('subtitle')}</p>
          </div>
          <div className="flex flex-col md:flex-row-reverse w-full items-start justify-between mb-28">
            <div className="w-full md:w-1/2 flex flex-col-reverse md:flex-col gap-8 relative mb-20">
              <div className="absolute w-[120vw] right-[50%] top-[100%] md:hidden">
                <ResponsiveImage
                  src="backgrounds/blue-glass-with-sphere"
                  alt="Ldm organization"
                  width="466.63"
                  height="466.63"
                  suffix="-q75"
                  className="speed-80"
                />
              </div>
              <p className="p1 mb-4 fade-in-right">{t('content')}</p>
              <div className="w-[100vw] md:w-full left-[50%] translate-x-[-50%] relative md:left-0 md:translate-x-0 ">
                <div className="md:rounded-2xl overflow-hidden w-full pb-[66%] relative">
                  <ResponsiveImage
                    src="ldm-organization/ldm-organization-photo"
                    alt={t('photoAlt')}
                    className="w-auto h-[120%] object-center object-cover absolute"
                    width="570.8"
                    height="378"
                    suffix="-q75"
                    dataSpeed="0.9"
                  />
                </div>
              </div>
            </div>
            <Accordion
              cards={cards}
              wrapperClasses="w-full md:w-5/12 relative fade-in-place"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default LdmOrganization
