import React, {
  useContext,
  useCallback,
  createContext,
  useState,
  useRef,
} from 'react'
import { useSearchParams } from 'react-router-dom'
/**
 * constants
 */
/* import {
} from './constants' */

const AppContext = createContext()

export default function useAppContext() {
  const context = useContext(AppContext)
  if (context === undefined) {
    throw new Error('AppContext must be used within a AppContextProvider')
  }

  return context
}

export const AppContextProvider = (props) => {
  let [searchParams, setSearchParams] = useSearchParams()

  /* 
  console.log('internform param passed', searchParams.has('internform'))
  console.log('universityform param passed', searchParams.has('universityform'))
  */
  const children = props.children
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const [isApplyFormOpen, setIsApplyFormOpen] = useState(
    searchParams.has('internform')
  )

  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false)
  const [currentVideo, setCurrentVideo] = useState(null)

  const [isUniversitiesFormOpen, setIsUniversitiesFormOpen] = useState(
    searchParams.has('universityform')
  )

  const isScrollPaused =
    isMenuOpen || isApplyFormOpen || isVideoModalOpen || isUniversitiesFormOpen

  const navbarRef = useRef(null)

  /**
   * functions to control the menu drawer
   */

  const openMenu = useCallback(() => {
    setIsMenuOpen(true)
  }, [])

  const closeMenu = useCallback(() => {
    setIsMenuOpen(false)
  }, [])

  /**
   * functions to control the apply form drawer
   */
  const openApplyForm = useCallback(() => {
    setIsApplyFormOpen(true)
  }, [])

  const closeApplyForm = useCallback(() => {
    if (searchParams.has('internform')) {
      setSearchParams({})
    }
    setIsApplyFormOpen(false)
  }, [])

  /**
   * functions to control the video modal
   */
  const openVideoModal = useCallback(() => {
    setIsVideoModalOpen(true)
  }, [])

  const closeVideoModal = useCallback(() => {
    setIsVideoModalOpen(false)
  }, [])

  /**
   * functions to control the apply form drawer
   */
  const openUniversitiesForm = useCallback(() => {
    setIsUniversitiesFormOpen(true)
  }, [])

  const closeUniversitiesForm = useCallback(() => {
    if (searchParams.has('universityform')) {
      setSearchParams({})
    }
    setIsUniversitiesFormOpen(false)
  }, [])

  /**
   * functions to store the scroll smoother instance in context
   */

  return (
    <AppContext.Provider
      value={{
        isMenuOpen,
        openMenu,
        closeMenu,
        isApplyFormOpen,
        openApplyForm,
        closeApplyForm,
        navbarRef,
        isVideoModalOpen,
        openVideoModal,
        closeVideoModal,
        currentVideo,
        setCurrentVideo,
        isUniversitiesFormOpen,
        openUniversitiesForm,
        closeUniversitiesForm,
        isScrollPaused,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
