import IconButtonBase from "../../../components/icon-button-base"

function FooterLink(props) {

  const links = props.links || false
  
  if (!links) return null
  
  return <ul className="space-y-8 pt-6">
    {links.map((link, index) => {
      let { label, subLinks, ...attr } = link
      //console.log(label, subLinks, attr)
      return (
        <li className="" key={index}>
          <a
            className="move-icon-to-right-on-hover inline-block relative pr-12 text-footer-link whitespace-pre-line md:whitespace-nowrap"
            {...attr}
          >
            {label}
            <IconButtonBase
              iconUrl={process.env.PUBLIC_URL +
                '/assets/images/buttons/slider-arrow.svg'}
              alt="arrow"
              wrapperClasses="absolute right-0 top-[50%] translate-y-[-50%] w-12" />
          </a>
          {subLinks && (
            <ul className="space-y-2 pt-4 pl-8">
              {subLinks.map((subLink, index) => {
                let { label, ...attr } = subLink
                return (
                  <li className="" key={index}>
                    <a
                      className="inline-block relative transition-colors duration-300 hover:text-secondary"
                      {...attr}
                    >
                      {label}
                    </a>
                  </li>
                )
              })}
            </ul>
          )}
        </li>
      )
    })}
  </ul>
}

export default FooterLink
