import ActionWrapper from '../action-wrapper'

const ItemListNumber = ({ index }) => {
  let listNumber = ('00' + (index + 1 + '.')).slice(-3)
  return (
    <span className="list-number absolute left-0 top-0 font-semibold">
      {listNumber}
    </span>
  )
}

function OrderedList(props) {
  const heading = props.heading || null
  const headingClasses = props.headingClasses || ''
  const items = props.items || []

  return (
    <>
      {heading && (
        <h4 className={`font-bold mb-4 md:mb-2 ${headingClasses}`}>{heading}</h4>
      )}
      <ol className="list-none space-y-4 md:space-y-2 ">
        {items.map((item, index) => (
          <li className="p1 pl-10 relative" key={index}>
            <ItemListNumber index={index} />
            <ActionWrapper
              className=""
              action={item.action || null}
              href={item.href || null}
              to={item.to || null}
              arrow
              linkClasses="relative underline underline-offset-2 overflow-visible"
            >
              <span key={index}>{item.text}</span>
            </ActionWrapper>
          </li>
        ))}
      </ol>
    </>
  )
}

export default OrderedList
