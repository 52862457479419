import ResponsiveImage from "../../../../../components/responsive-image";

function SkillSlide({ children = null, slideWrapperClasses = "", slideClasses = "", background }) {
  return (
    <div className={`text-center origin-center ${slideWrapperClasses}`} >
      <div className={`relative rounded-full slide-content left-[100%] translate-x-[-50%] ${slideClasses}`}>
         <ResponsiveImage
          src={`skills-ldmer/skill-slider-item-fondo-${background}`}
          className="absolute w-full h-full object-contain"
          alt="Decoration"
          width="300"
          height="300"
          suffix="-q75"
        />
        <p className="text-base md:text-lg lg:text-2xl w-full text-center top-[50%] translate-y-[-50%] absolute p-[6.66%]">
          {children}
        </p>
      </div>
    </div>
  )
}

export default SkillSlide;