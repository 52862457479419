import { gsap } from 'gsap'
import { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import ArrowIconButton from '../../../../../components/arrow-icon-button'
import { inPublicAssets } from '../../../../../utilities/functions'
import useAppContext from '../../../../../utilities/app-context'
import IconButtonBase from '../../../../../components/icon-button-base'
import useSelector from '../../../../../hooks/use-selector'

function HeroSlider() {
  const { t: translate } = useTranslation()
  const t = (str, options) => translate('home.hero.' + str, options)

  const { openVideoModal, setCurrentVideo } = useAppContext()

  const [q, ref] = useSelector()

  const currentSlide = useRef(0)
  const slideBulletsRef = useRef([])

  const slides = t('slides', { returnObjects: true })

  const slideTransitionDuration = 0.75

  const nextSlide = useCallback(() => {
    const slideIndex = gsap.utils.wrap(
      0,
      slides.length,
      currentSlide.current + 1
    )
    gsap
      .timeline()
      .set(q(`.slide-${slideIndex}`), { zIndex: 2 })
      .fromTo(
        q(`.slide-${slideIndex}`),
        { opacity: 0, xPercent: +100 },
        { xPercent: 0, duration: slideTransitionDuration, opacity: 1 }
      )

      .set(q(`.slides:not(.slide-${slideIndex})`), { xPercent: -500, zIndex: 1 })
      .set(q(`.slide-${slideIndex}`), { zIndex: 1 })
    currentSlide.current = slideIndex
    setActiveBullet(slideIndex)
    return true
  }, [])

  const previousSlide = useCallback(() => {
    const slideIndex = gsap.utils.wrap(
      0,
      slides.length,
      currentSlide.current - 1
    )
    gsap
      .timeline()
      .set(q(`.slide-${slideIndex}`), { zIndex: 2 })
      .fromTo(
        q(`.slide-${slideIndex}`),
        { opacity: 0, xPercent: -100 },
        { xPercent: 0, duration: slideTransitionDuration, opacity: 1 }
      )
      .set(q(`.slides:not(.slide-${slideIndex})`), { xPercent: -500, zIndex: 1 })
      .set(q(`.slide-${slideIndex}`), { zIndex: 1 })
    currentSlide.current = slideIndex
    setActiveBullet(slideIndex)
    return true
  }, [])

  const setActiveBullet = useCallback((currentIndex) => {
    slideBulletsRef.current.forEach((bullet, index) => {
      if (index === currentIndex) {
        bullet.classList.add('bg-main')
        bullet.classList.remove('bg-transparent')
      } else {
        bullet.classList.add('bg-transparent')
        bullet.classList.remove('bg-main')
      }
    })
  }, [])

  return (
    <div
      ref={ref}
      id="hero-slider"
      className="w-full md:w-1/2 z-10 mb-12 md:mb-0"
    >
      <div className="z-10 slider-wrapper w-full md:w-11/12 border-[2px] border-white rounded-full bg-gradient-to-tr from-[rgb(224,231,237)] via-to-[rgb(237,240,243)] to-[rgb(237,240,243)] relative">
        <div className="z-10 slider-inner-wrapper w-[92%] pb-[92%] relative rounded-full overflow-hidden m-[4%]">
          <div className="z-10 slides-container absolute inset-0">
            {slides.map((slide, index) => {
              return (
                <div
                  style={{ zIndex: index === currentSlide.current ? 2 : 1 }}
                  className={`absolute inset-0 slides ${'slide-' + index}`}
                  key={index}
                >
                  <img
                    src={inPublicAssets('hero/slides/' + slide.image + '.webp')}
                    className="relative w-[105%] h-[105%] max-w-none object-cover rounded-full top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                  />
                  <button
                    className="scale-on-hover w-1/5 h-1/5 md:w-16 md:h-16 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                    onClick={() => {
                      setCurrentVideo(slide)
                      openVideoModal()
                    }}
                  >
                    <IconButtonBase
                      wrapperClasses="relative border-2 border-white bg-lighten rounded-full bg-transparent"
                      iconClasses="w-1/3 h-1/3"
                      iconUrl={inPublicAssets('buttons/play-icon.svg')}
                    />
                  </button>
                </div>
              )
            })}
          </div>
        </div>
        <div className="z-10 slider-controls-bullets absolute top-[calc(100%+1rem)] left-1/2 -translate-x-1/2 flex justify-between items-center h-4 ">
          {slides.map((slide, index) => (
            <div className="px-4" key={index}>
              <div
                ref={(e) => (slideBulletsRef.current[index] = e)}
                key={index}
                className={`slide-bullet ${'slide-bullet-' + index} ${
                  index === currentSlide.current ? 'bg-main' : 'bg-transparent'
                } h-2 w-2 border border-main rounded-full transition-colors duration-500`}
              />
            </div>
          ))}
        </div>
        <ArrowIconButton
          onClick={nextSlide}
          innerClasses="hover:scale-10 transition"
          buttonClasses="z-10 w-[15%] absolute top-1/2 left-full -translate-x-1/2 -translate-y-1/2 z-10"
          wrapperClasses="w-full bg-white"
        />
        <ArrowIconButton
          onClick={previousSlide}
          buttonClasses="z-10 w-[15%] absolute top-1/2 left-0 -translate-x-1/2 -translate-y-1/2 z-10 rotate-180"
          wrapperClasses="w-full bg-white"
        />
      </div>
    </div>
  )
}

export default HeroSlider
