import { useTranslation } from 'react-i18next'
import IconCard from '../../../../components/icon-card'
import LargeCta from '../../../../components/large-cta'

function Universidades() {
  const { t: translate } = useTranslation()
  const t = (str, options) => translate('home.universidades.' + str, options)

  const cards = t('cards', { returnObjects: true })

  const iconRoute = (icon) => `universidades/${icon}`

  return (
    <section id="universidades" className="">
      <div className="container mx-auto pt-20 pb-16">
        <div className="wrapper mb-20">
          <div className="title-wrapper md:text-center">
            <h2 className="mb-4 fade-in-place">{t('title')}</h2>
            <p className="p1 fade-in-bottom">{t('subtitle')}</p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row items-start md:items-center justify-start md:justify-between -mx-8 mb-10 fade-in-place-children">
          {cards.items.map((item, index) => (
            <IconCard
              key={index}
              icon={iconRoute(item.icon)}
              title={item.title}
              className="w-full md:w-1/3"
            />
          ))}
        </div>
        <div className="fade-in-place">
          <LargeCta />
        </div>
      </div>
    </section>
  )
}
export default Universidades
