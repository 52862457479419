import { useTranslation } from 'react-i18next'
import WithLetsConnectUniversitiesLink from '../layout/universities-contact/with-lets-connect-universities-link'
import TextLinkArrow from './text-link-arrow'

function LargeCta(props) {
  const i18nNamespace = props.i18nNamespace || 'home.universidades.cta'
  const { t: translate } = useTranslation()
  const t = (str, options) => translate(i18nNamespace + '.' + str, options)

  const wrapperClasses = props.wrapperClasses || ''

  return (
    <div
      className={`w-full md:w-8/12 mx-auto text-center py-6 px-12 rounded-full bg-teal mb-16 ${wrapperClasses}`}
    >
      <p className="p1 font-bold mb-4">{t('text')}</p>
      <WithLetsConnectUniversitiesLink className="">
        <TextLinkArrow>{t('link')}</TextLinkArrow>
      </WithLetsConnectUniversitiesLink>
    </div>
  )
}

export default LargeCta
