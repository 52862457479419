import useAppContext from "../utilities/app-context";
  
function WithApplyNowLink(props) {
  const { openApplyForm } = useAppContext();
  const buttonClasses = props.buttonClasses || "";
  return (
    <button onClick={openApplyForm} className={buttonClasses}>
      {props.children}
    </button>
  );
}

export default WithApplyNowLink;