import { useTranslation } from 'react-i18next'

function Copyright() {
  const { t: translate } = useTranslation()
  const t = (str, options) => translate('footer.' + str, options)

  const year = new Date().getFullYear()

  return (
    <div className="flex flex-col gap-2 text-sm md:text-sm">
      <div className="footer-copyright">
        {t('copyright',{year: year})}
      </div>
      <div className="part-of">
        {t('part-of')}
      </div>
    </div>
  )
}


export default Copyright