import { gsap } from 'gsap'
import { useCallback, useLayoutEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import useSelector from '../../hooks/use-selector'
import useAppContext from '../../utilities/app-context'
import { fullViewportHeight, inPublicAssets } from '../../utilities/functions'
import CloseDrawerButton from '../../components/close-drawer-button/close-drawer-button'
import { modalHideTransitionLength, modalShowTransitionLength } from '../../utilities/constants'

function VideoModal({
  onSubmit = () => console.log('No submit function provided'),
  ...props
}) {
  const { t: translate } = useTranslation()
  const t = (str, options) => translate('lets-connect.form.' + str, options)

  /**
   * Use selector to target inner items of the drawer
   */
  const [q, ref] = useSelector()

  /**
   * Ref used to prevent initial animation on load
   */
  const firstRender = useRef(true)

  const {
    isVideoModalOpen,
    closeVideoModal,
    currentVideo,
    setCurrentVideo,
  } = useAppContext()

  /**
   * Animation to open the drawer
   */
  const openDrawerAnimation = useCallback(() => {
    const timeline = gsap
      .timeline()
      .to(ref.current, {
        autoAlpha: 1,
        height: fullViewportHeight() + 'px',
        duration: modalShowTransitionLength,
      })
      .fromTo(
        q('.stagger'),
        { opacity: 0, yPercent: 20 },
        { opacity: 1, yPercent: 0, stagger: 0.1, duration: 0.5 },
        '-=0.1'
      )
      .call(() => {
        q('#video-player')[0].play()
      })
    return timeline
  }, [])

  /**
   * Animation to close the drawer
   */
  const closeDrawerAnimation = useCallback(() => {
    const timeline = gsap
      .timeline({ immediateRender: false })

      .call(() => {
        if (Boolean(currentVideo)) {
          q('#video-player')[0].pause()
        }
      })
      .to(ref.current, { height: 0, autoAlpha: 0, duration: modalHideTransitionLength })
      .call(setCurrentVideo(null))
    return timeline
  }, [])

  useLayoutEffect(() => {
    gsap.set(ref.current, { autoAlpha: 0, height: 0 })
  }, [])

  useLayoutEffect(() => {
    /**
     * Use Effect to watch open/closed state of drawer
     */
    if (!firstRender.current) {
      if (isVideoModalOpen) {
        openDrawerAnimation()
      }
      if (!isVideoModalOpen) {
        closeDrawerAnimation()
      }
    }
    firstRender.current = false
  }, [isVideoModalOpen])

  return (
    <div
      ref={ref}
      id="video-modal"
      className="transition-none fixed top-0 left-0 w-screen h-0 bg-cover overflow-hidden invisible z-50"
    >
      <img
        src={inPublicAssets('backgrounds/menu-drawer-q75.webp')}
        loading="eager"
        className="absolute top-0 left-0 h-fullscreen w-full object-cover"
      />
      <div className="min-h-fullscreen max-h-full relative overflow-y-scroll flex flex-col justify-center items-center">
        <div className="w-video h-video">
          {Boolean(currentVideo) && (
            <video
              id="video-player"
              width="1000"
              height="1000"
              controls
              className="w-full h-full"
              poster={
                Boolean(currentVideo?.poster)
                  ? inPublicAssets(
                      'hero/slides/videos/' + currentVideo.poster + '.webp'
                    )
                  : null
              }
            >
              {currentVideo.sources?.map((source, index) => (
                <source
                  key={source+index}
                  src={inPublicAssets('hero/slides/videos/' + source)}
                  type="video/mp4"
                />
              ))}
            </video>
          )}
        </div>
      </div>
      <div className="brand absolute left-[var(--container-edge)] top-[calc(var(--scrolled-navbar-height)/2)] -translate-y-1/2">
        <img
          src={inPublicAssets('brand/logo LDM.svg')}
          className="h-full w-auto"
          alt={translate('navigation-bar.brand-link.alt')}
        />
      </div>
      <CloseDrawerButton onClick={closeVideoModal} />
    </div>
  )
}

export default VideoModal
