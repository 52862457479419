import { useTranslation } from "react-i18next";

function HeroContent() {
  const {t: translate} = useTranslation();
  const t = (str) => translate("home.hero." + str);
  return (
    <div id="hero-content" className="py-4 md:py-0 flex flex-col justify-center items-center md:w-1/2 relative">
      <div className="content-wrapper w-4/6 text-center relative">
        <h1 className="mb-4 -mx-8">{t("title")}</h1>
        <p className="hidden md:block  text-lg">{t("content")}</p>
      </div>
    </div>
  );
}

export default HeroContent;
