import { useTranslation } from 'react-i18next'
import SocialBar from '../../components/social-bar/social-bar'
import ApplyNowLink from './components/apply-now-link'
import Copyright from './components/copyright'
import FooterLink from './components/footer-link'

function Footer() {
  const { t: translate } = useTranslation()
  const t = (str, options) => translate('footer.' + str, options)

  const links = t('links', { returnObjects: true }) || []

  return (
    <footer
      className={`footer bg-footer bg-cover pb-40`}
    >
      <div className="container mx-auto h-full">
        <div className="wrapper py-16 h-full">
          <div className="grid gap-12 md:gap-0 grid-cols-1 md:grid-cols-2 md:grid-rows-2 h-full">
            <div className="md:col-span-1 md:row-span-1">
              <div className="footer-title mb-4">
                <h2>{t('title')}</h2>
              </div>
              <div className="apply-now-cta">
                <ApplyNowLink label={t('cta.label')} />
              </div>
            </div>
            <div className="md:col-span-1 md:row-span-2">
              <div className="md:ml-1/6">
                <FooterLink links={links} />
              </div>
            </div>
            <div className="md:col-span-1 md:row-span-1 self-end">
              <SocialBar />
              <Copyright />
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
