import { useTranslation } from 'react-i18next'
import PartnersLogos from './components/partners-logos'

function PartnersNetworking() {
  const { t: translate } = useTranslation()
  const t = (str, options) => translate('home.partners-networking.' + str, options)

  return (
    <section id="partners-networking" className="pt-12 pb-28">
      <div className="container mx-auto">
        <div className="wrapper">
          <h2 className="md:ml-1/12 mb-10 fade-in-place">{t('title')}</h2>
          <div className="flex flex-col justify-start md:flex-row md:justify-between items-stretch md:items-start">
            <div className="md:ml-1/12 w-full md:w-5/12">
              <p className="p1 fade-in-left">{t('content')}</p>
            </div>
            <div className="w-full md:w-1/2 relative fade-in-place">
              <PartnersLogos />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PartnersNetworking
