import { accordionArrow } from '../../utilities/constants'

function Select({
  name = '', //field name,
  id = '', //field id,
  register = () => {}, //register function from react-hook-form,
  validation = {}, //validation object from react-hook-form,
  placeholder = '', //placeholder text,
  options = [], //options array,
  errors = {},
}) {
  return (
    <>
      <select
        name={name}
        id={id}
        tabIndex={0}
        className={`pr-4 ${errors?.[name] ? 'text-main-red' : ''}`}
        defaultValue={''}
        {...register(name, {
          ...validation,
        })}
      >
        {placeholder && (
          <option value="" disabled>
            {placeholder}
          </option>
        )}
        {options.map((option, index) => (
          <option value={option.value} key={index}>
            {option.label}
          </option>
        ))}
      </select>
      <div className="select-arrow pointer-events-none absolute top-1/2 -translate-y-1/2 right-2 text-main">
        <img
          src={accordionArrow}
          alt="select menu toggler"
          className="w-4 h-4 object-contain"
        />
      </div>
    </>
  )
}
export default Select
